import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import passportPhotoExample from '@helpers/passport-photo-example'
import { commonBanks } from '@constants/banking-institutions'
import { fullName } from '@modules/form-wizard/utils'
import toastr from '@modules/toastr'
import Swal from 'sweetalert2'

export default class JunkanooEmployment extends React.Component {
  resource = '/junkanoo_employments'
  form_key = 'junkanoo_employment'
  defaults = {}

  // Delete lines as needed
  custom_acceptance = true
  // fee           = 99
  free          = true
  // postpaid      = true
  skip_location = true
  hide_buttons  = {
    status: true,
    deny: false,
    fee: true,
  }
  custom_actions = [
    {
      text: 'Approve Application',
      icon: 'check-square',
      roles: [['officer']],
      fn: record =>
        Swal.fire({
          title: 'Approve Application',
          html: `
            What is your recommendation regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'select',
          inputOptions: {
            'approved': "I approve this applicant",
            'denied': "I deny this applicant",
          },
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
        }).then(async result => {
          if (result.value) {
            try {
              await $app.axios.put(this.resource + '/update_application', {
                [this.form_key]: {
                  form_num: record.form_num,
                  application_decision: result.value,
                },
              })
              toastr.success('Success', 'Application status updated successfully')
            } catch (err) {
              console.error(err)
              toastr.error('Error', 'Unable to approve application')
            }
          }
        }),
    },
    // {
    //   text: 'Recommend To Cultural Officer',
    //   icon: 'check-square',
    //   roles: [['officer']],
    //   fn: record =>
    //     Swal.fire({
    //       title: 'Recommend To Cultural Officer',
    //       html: `
    //         What is your recommendation regarding the applicant,
    //         <strong>${fullName(record.user, 'initial')}</strong>?
    //       `,
    //       input: 'select',
    //       inputOptions: {
    //         'approved': "I recommend this applicant",
    //         'denied': "I do not recommend this applicant",
    //       },
    //       icon: 'question',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       confirmButtonText: 'Confirm',
    //     }).then(async result => {
    //       if (result.value) {
    //         try {
    //           await $app.axios.put(this.resource + '/update_application', {
    //             [this.form_key]: {
    //               form_num: record.form_num,
    //               application_decision: result.value,
    //             },
    //           })
    //           toastr.success('Success', 'Application status updated successfully')
    //         } catch (err) {
    //           console.error(err)
    //           toastr.error('Error', 'Unable to approve application')
    //         }
    //       }
    //     }),
    // },
  ]

  validate = null

  foremost = null

  fields = form => [
   /* {
      name: 'how_did_hear',
      label: 'How did you hear about this program?',
      options: [
                  'Whatsapp', 
                  'Social Media', 
                  'Newspaper', 
                  'Radio',
                  'Word of Mouth',
                  'Other',
                ],
    }, 
    {
      name: 'date_of_birth',
      type: 'date',
    },*/
    ":: Personal Preferences",
    {
      name: 'preferred_parade',
      label: 'Please choose your preferred parade(s) to work',
      multi: true,
      options: ['Boxing day parade', "New year's day parade", 'Junior Junkanoo parade'],
      hint: 'Please select all the parades you intend to work.',
    },
    {
      name: 'preferred_area',
      label: 'Please choose your preferred area to work',
      options: ['Entry Point', 'Bleacher Marshall', 'Facilities', 'Protocol', 'Supplies'],
    },

    ":: Banking Info",
    {
      name: 'bank_name',
      label: 'Bank Name',
      options: commonBanks,
    },
    {
      name: 'bank_branch',
      label: 'Bank Branch Transit Number',
      type: 'integer',
    },
    {
      name: 'bank_acct_numb',
      label: 'Bank Account Number',
      type: 'integer',
    },
    {
      name: 'bank_acct_type',
      label: 'Savings or checking account?',
      options: ['savings', 'checking']
    },
    {
      name: 'emergency_contact_list',
      label: 'Emergency Contact',
      fields: [
        {
          name: 'first_name',
          // label: 'First name of parent/guardian'
        },
        {
          name: 'last_name',
          // label: 'Last name of parent/guardian'
        },
        {
          name: 'phone_contact',
          type: 'phone',
        },
        {
          name: 'relationship',
          label: 'Relationship to applicant',
          options: ['parent', 'guardian', 'spouse', 'relative', 'friend'],
        },
        // {
        //   name: 'doc_first_name',
        //   label: `Physician's first name`,
        // },
        // {
        //   name: 'doc_last_name',
        //   label: `Physician's last name`,
        // },
        // {
        //   name: 'doc_phone',
        //   label: `Physician's phone number`,
        //   type: 'phone',
        // },

      ],
     
    },
   
    "::SPACER",
    {
      name: 'personal_statement',
      label: 'What experiences have you had that would help you succeed in this program and why is it important for you to do this program at this time in your life?',
      type: 'textarea',
    },

    ":: Uploads",
    {
      key: 'image.0',
      name: 'banking_info_upload',
      label: 'Bank Statement',
      type: 'file:all',
    },
    {
      key: 'image.1',
      name: 'passport_upload',
      label: 'Passport',
      type: 'file:image',
      hint: passportPhotoExample,
    },
    {
      key: 'image.2',
      name: 'nib_card_upload',
      label: 'NIB Card',
      type: 'file:all',
    },
    {
      name: 'acceptance',
      type: 'checkbox',
      label: 'By clicking here you agree that the information provided is accurate.',
      use_as_acceptance: true,
    },
    {
      heading: '*<b>Please Note</b>: No changes can be made to the application once submitted.*',
      save: false,
      view: false,
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
